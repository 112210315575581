export default function FeatureIcon({ icon, title, value }) {
  var image;
  switch (icon) {
    case "bluetooth":
      image = "/images/icons/bluetooth.svg";
      break;
    case "navigation":
      image = "/images/icons/navigation.svg";
      break;
    case "blind":
      image = "/images/icons/blind.svg";
      break;
    case "camera":
      image = "/images/icons/camera.svg";
      break;
    case "keys":
      image = "/images/icons/keys.svg";
      break;
    case "seat":
      image = "/images/icons/seat.svg";
      break;
    case "sun":
      image = "/images/icons/sun.svg";
      break;
    case "wheel":
      image = "/images/icons/wheel.svg";
      break;
    case "gauge":
      image = "/images/icons/gauge.svg";
      break;
    case "paint":
      image = "/images/icons/paint.svg";
      break;
    case "transmission":
      image = "/images/icons/transmission.svg";
      break;
    default:
      image = "/images/icons/gauge.svg";
  }

  return (
    <li>
      <div className={"imageWrapper"}>
        <img src={image} />
      </div>
      <div>
        <h3>{title}</h3>
        <h4>{value}</h4>
      </div>
    </li>
  );
}
