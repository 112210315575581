// import styles from '../styles/CurrentListing.module.scss'
import { useEffect } from "react";
import { setHash } from "../utils/url";
import Contact from "./Contact";

export default function Service(props) {
  const defaultMessage = `
  I'd like to book an appointment to service my vehicle: \n
  `;

  useEffect(() => {
    setHash("service", true);
  }, []);

  return (
    <div key={"overlay"} className={"modalOverlay"} onClick={props.exit}>
      <div
        className={"modalContainer"}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Contact
          onBack={props.exit}
          title={"Service Request"}
          defaultMessage={defaultMessage}
        />
      </div>
    </div>
  );
}
