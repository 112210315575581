import styles from "../styles/progress.module.scss";

export default function ProgressBar({ progress }) {
  return (
    <div className={styles.wrapper}>
      <div style={{ width: progress + "%" }} className={styles.fill}>
        <span className={styles.text}>{progress} %</span>
      </div>
    </div>
  );
}
