export function parseHash() {
  const hash = window.location.hash;
  const contents = hash.split("#")[1];
  if (!contents) {
    return {};
  }
  const split = contents.split("&");

  let ret = {};

  for (let i = 0; i < split.length; ++i) {
    const KeyValue = split[i].split("=");
    if (KeyValue.length !== 2) {
      continue;
    }

    ret[KeyValue[0]] = KeyValue[1];
  }
  return ret;
}

export function setHash(key, value) {
  const currentHash = parseHash();
  currentHash[key] = value;
  const hashArray = [];

  for (const key in currentHash) {
    if (currentHash.hasOwnProperty(key)) {
      const value = currentHash[key];
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(value);
      hashArray.push(`${encodedKey}=${encodedValue}`);
    }
  }

  const hashString = hashArray.join("&");
  if (window.location.hash !== hashString) {
    window.location.hash = hashString;
  }
}
