import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { db } from "../utils/firebase";
import { addDoc, collection } from "firebase/firestore";

import "react-datepicker/dist/react-datepicker.css";

export default function Contact(props) {
  const { defaultMessage, onBack, showDate, title } = props;
  const [requestedDate, setRequestedDate] = useState(new Date());
  const [message, setMessage] = useState(defaultMessage);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = () => {
    addDoc(collection(db, "submissions"), {
      to: window.location.hostname,
      email,
      phone,
      name,
      requestedDate,
    }).then((doc) => {
      console.log("CREATED SUBMISSION DOC", doc.id);
      setSubmitted(true);

      setTimeout(() => {
        onBack();
      }, 3000);
    });
  };

  if (submitted) {
    return (
      <div className={"contactContainer"}>
        <div className={"modalHeader"}>
          <FontAwesomeIcon icon={faArrowLeft} className='backIcon' />
          <h2>{title}</h2>
        </div>
        <div className={"modalContents"}>
          <h2 className={"submissionReceived"}>
            {" "}
            Submission Received!
            <br /> <br />
            Thanks! We'll be in touch shortly to confirm your appointment.
          </h2>
        </div>
      </div>
    );
  }
  return (
    <div className={"contactContainer"}>
      <div className={"modalHeader"}>
        <FontAwesomeIcon
          className={"backIcon"}
          icon={faArrowLeft}
          onClick={() => {
            if (props.onBack) props.onBack();
          }}
        />

        <h2>{title}</h2>
      </div>
      <div className={"modalContents"}>
        <div>
          <label>When are you available to come in?</label>
          <DatePicker
            selected={requestedDate}
            onChange={(date) => setRequestedDate(date)}
          />
        </div>
        <div
          className={"formContainer"}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <form onSubmit={onSubmit}>
            <label>
              Name:
              <input
                placeholder={"John Doe"}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              ></input>
            </label>
            <label>
              Phone:
              <input
                placeholder={"888-888-8888"}
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              ></input>
            </label>
            <label>
              Email:
              <input
                placeholder={"John.Doe@gmail.com"}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              ></input>
            </label>
            <label>
              {" "}
              Message:
              <textarea
                value={message}
                rows={"3"}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              ></textarea>
            </label>
          </form>
        </div>
        <button
          className={"modalCTA"}
          onClick={(e) => {
            e.stopPropagation();
            onSubmit();
          }}
        >
          Submit Request
        </button>
      </div>
    </div>
  );
}
