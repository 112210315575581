// import styles from '../styles/CurrentListing.module.scss'

import { intToDollars, intToMileage } from "../utils/numbers";
import Carousel from "./Carousel";
import kijijiLogo from "../assets/kijiji.jpeg";
import { useEffect, useState } from "react";
import comingSoonImage from "../assets/coming_soon.jpg";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setHash } from "../utils/url";
import FullscreenImage from "./FullscreenImage";
import TextWithEllipsis from "./TextElipsis";
import FeatureIcon from "./featureIcon";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../utils/firebase";

function kijijiLink(listing) {
  return "https://www.kijiji.ca/v-view-details.html?adId=m" + listing.id;
}

export default function CurrentListing(props) {
  const [fullScreenImage, setFullScreenImage] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const { listing } = props;
  if (!listing.options) {
    listing.options = [];
  }
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    setHash("listingId", listing.id);
  }, []);

  const onSubmit = () => {
    addDoc(collection(db, "submissions"), {
      to: window.location.hostname,
      listingId: listing.id,
      listingTitle: listing.title,
      form: "Listing Details",
      email,
      phone,
      firstName,
      lastName,
      submissionDate: new Date().toLocaleDateString()
    }).then((doc) => {
      console.log("CREATED SUBMISSION DOC", doc.id);
      setSubmitted(true);

      setTimeout(() => {
        setSubmitted(false);
      }, 3000);
    });
  };

  return (
    <>
      {fullScreenImage !== null ? (
        <FullscreenImage
          image={listing.images[fullScreenImage]}
          exit={() => {
            setFullScreenImage(null);
          }}
          switchImage={(delta) => {
            setFullScreenImage((prevFullScreenImage) => {
              return (
                (listing.images.length + (prevFullScreenImage + delta)) %
                listing.images.length
              );
            });
          }}
        />
      ) : null}
      <div key={"overlay"} className={"modalOverlay"} onClick={props.exit}>
        <div
          className={"modalContainer"}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className={"modalHeader"}>
            <FontAwesomeIcon
              icon={faArrowLeft}
              className='backIcon'
              onClick={props.exit}
            />
            <h2>Listing Details </h2>
          </div>
          <div className='modalContents'>
            <h3>{listing.title}</h3>
            <Carousel
              onClick={(value) => {
                setFullScreenImage(value);
              }}
            >
              {listing.images.length > 0
                ? listing.images.map((img, idx) => <img src={img} />)
                : [comingSoonImage].map((i) => <img src={comingSoonImage} />)}
            </Carousel>
            <div className={"listingContact"}>
              <p>
                <strong>Price:</strong> {intToDollars(listing.price)}
              </p>
              {submitted ? null : (
                <form>
                  <p>
                    <strong>Request Information:</strong>
                    <br />
                    Hello, my name is{" "}
                    <input
                      value={firstName}
                      onChange={setFirstName}
                      placeholder='First Name'
                    />{" "}
                    <input
                      value={lastName}
                      onChange={setLastName}
                      placeholder='Last Name'
                    />{" "}
                    and I'm interested in this <strong>{listing.title}</strong>.
                    You can reach me at{" "}
                    <input
                      value={email}
                      onChange={setEmail}
                      placeholder='email@gmail.com'
                    />{" "}
                    or by phone at{" "}
                    <input
                      value={phone}
                      onChange={setPhone}
                      placeholder='(222) 222-2222'
                    />{" "}
                    <br /> Thank You!
                  </p>

                  <div className='formControls'>
                    <button onClick={onSubmit} className='primary'>
                      Submit
                    </button>
                  </div>
                </form>
              )}
            </div>
            <ul className={"featureList"}>
              {listing.mileage && (
                <FeatureIcon
                  icon={"mileage"}
                  title={"mileage"}
                  value={intToMileage(listing.mileage)}
                />
              )}
              {listing.features.exteriorColor && (
                <FeatureIcon
                  icon={"paint"}
                  title={"Exterior Color"}
                  value={listing.features.exteriorColor}
                />
              )}
              {listing.features.interiorColor && (
                <FeatureIcon
                  icon={"seat"}
                  title={"Interior Color"}
                  value={listing.features.interiorColor}
                />
              )}
              {listing.features.interiorColor && (
                <FeatureIcon
                  icon={"transmission"}
                  title={"Transmission"}
                  value={listing.features.transmission}
                />
              )}
              {listing.options.indexOf("Bluetooth") !== -1 && (
                <FeatureIcon icon={"bluetooth"} title={"Bluetooth"} />
              )}
              {listing.options.indexOf("Backup Camera") !== -1 && (
                <FeatureIcon icon={"camera"} title={"Backup Camera"} />
              )}

              {listing.options.indexOf("Heated Seats") !== -1 && (
                <FeatureIcon icon={"seat"} title={"Heated Seats"} />
              )}
              {listing.options.indexOf("Leather Seats") !== -1 && (
                <FeatureIcon icon={"seat"} title={"Leather Seats"} />
              )}
              {listing.options.indexOf("Sunroof/Moonroof") !== -1 && (
                <FeatureIcon icon={"sun"} title={"Sunroof"} />
              )}
              {listing.options.indexOf("Remote Start") !== -1 && (
                <FeatureIcon icon={"keys"} title={"Remote Start"} />
              )}
              {listing.options.indexOf("Alloy Wheels") !== -1 && (
                <FeatureIcon icon={"wheel"} title={"Alloy Wheels"} />
              )}
              {listing.options.indexOf("Steel Wheels") !== -1 && (
                <FeatureIcon icon={"wheel"} title={"Steel Wheels"} />
              )}
              {listing.options.indexOf("Blind Spot Monitoring") !== -1 && (
                <FeatureIcon icon={"blind"} title={"Blind Spot Monitoring"} />
              )}
              {listing.options.indexOf("Navigation System") !== -1 && (
                <FeatureIcon icon={"navigation"} title={"Navigation System"} />
              )}
            </ul>

            <p>
              <strong>Description:</strong>
              <br />

              <TextWithEllipsis text={listing.description} />
            </p>
            <div className={"listingActions"}>
              <a
                href={kijijiLink(listing)}
                target={"_blank"}
                className={"kijijiLink"}
              >
                <img src={kijijiLogo} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
