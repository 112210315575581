import primeLogo from "../assets/primeAuto.png";
import facebookLogo from "../assets/socials/facebook.png";
import instagramLogo from "../assets/socials/instagram.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faCoffee,
  faEnvelope,
  faMapMarker,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

export default function Footer() {
  const fullAddress = "325 Weber St N, Waterloo, ON N2J 3H8";
  return (
    <div className={"footer"}>
      <img src={primeLogo} />

      <div className={"footerRow"}>
        <div className={"footerCol"}>
          <h3>Contact Us:</h3>
          <div className='grid'>
            <FontAwesomeIcon icon={faMapMarker}></FontAwesomeIcon>
            <a
              href={"https://maps.google.com/maps?q=" + fullAddress}
              target={"_blank"}
            >
              {fullAddress}
            </a>
            <FontAwesomeIcon icon={faPhone}></FontAwesomeIcon>
            <a href={"tel:8888888888"}>888-888-8888</a>
            <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
            <a href={"mailto:test@gmail.com"}>test@gmail.com</a>
          </div>
        </div>

        <div className={"footerCol"}>
          <h3>Store Hours:</h3>
          <div className='grid'>
            <FontAwesomeIcon icon={faClock}></FontAwesomeIcon>
            <span>
              <strong>Mon - Fri:</strong> 9:00am - 6:00pm
            </span>
            <FontAwesomeIcon icon={faClock}></FontAwesomeIcon>
            <span>
              <strong>Sat :</strong> 9:00am - 7:00pm
            </span>
            <FontAwesomeIcon icon={faClock}></FontAwesomeIcon>
            <span>
              <strong>Sun :</strong> closed
            </span>
          </div>
        </div>

        <div className={"footerCol"}>
          <h3>Socials:</h3>
          <div className='socialLinks'>
            <a target={"_blank"} href={"https://www.facebook.com/primeautoltd"}>
              <img src={facebookLogo} />
            </a>
            <a
              target={"_blank"}
              href={
                "https://www.instagram.com/explore/locations/910651219118017/prime-auto-ltd/"
              }
            >
              <img src={instagramLogo} />
            </a>
          </div>
        </div>
        <div className={"footerCol"}></div>
      </div>
    </div>
  );
}
