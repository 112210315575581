import React from "react";

import { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
//tests

export default function (props) {
  const { children, onClick } = props;
  const [windowSize, setWindowSize] = useState(-1);

  useEffect(() => {
    if (windowSize !== window.innerWidth) {
      setWindowSize(window.innerWidth);
    }
  }, [windowSize]);

  if (!children || !children.length) return null;

  return (
    <div className={"carouselContainer"}>
      <Carousel
        showArrows={true}
        showThumbs={true}
        showIndicators={true}
        infiniteLoop={true}
        dynamicHeight={false}
        className={"carousel"}
        emulateTouch={true}
        swipeable={false}
        centerMode={true}
        centerSlidePercentage={100}
        onClickItem={onClick}
        useKeyboardArrows={true}
      >
        {children}
      </Carousel>
    </div>
  );
}
