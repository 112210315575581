import { useEffect } from "react";

export default function FullscreenImage(props) {
  const { image, switchImage, exit } = props;
  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case "ArrowLeft":
          // Handle left arrow key press
          switchImage(-1);
          break;
        case "ArrowRight":
          // Handle right arrow key press
          switchImage(1);
          break;
        case "Escape":
          exit();
        default:
          // Handle other key presses if needed
          break;
      }
    };

    // Add event listener when component mounts
    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  return <img src={image} className={"fullscreen"} onClick={exit} />;
}
