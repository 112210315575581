// import styles from '../styles/CurrentListing.module.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";

import ProgressBar from "./ProgressBar";
import { setHash } from "../utils/url";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../utils/firebase";

export default function Finance(props) {
  const defaultMessage = `
  I'd like to book an appointment to discuss financing a vehicle: \n
  `;

  const [currStep, setCurrStep] = useState(0);

  const progressPercent = Math.floor(((currStep + 1) / 5) * 100);

  /**Section 1 */
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [birthday, setBirthday] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [sin, setSin] = useState("");
  const statuses = ["Married", "Single", "Divorced", "Other"];

  /**Section2 */

  const [addressType, setAddressType] = useState("");
  const addressTypes = ["Rent", "Mortgage", "Other"];

  const [monthlyPayment, setMonthlyPayment] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressCity, setAddressCity] = useState("");
  const [addressProvince, setAddressProvince] = useState("Ontario");
  const [addressPostCode, setAddressPostCode] = useState("");
  const [addressDuration, setAddressDuration] = useState("");

  const [prevAddressLine1, setPrevAddressLine1] = useState("");
  const [prevAddressCity, setPrevAddressCity] = useState("");
  const [prevAddressProvince, setPrevAddressProvince] = useState("Ontario");
  const [prevAddressPostCode, setPrevAddressPostCode] = useState("");

  const canadianProvinces = [
    "Alberta",
    "British Columbia",
    "Manitoba",
    "New Brunswick",
    "Newfoundland and Labrador",
    "Nova Scotia",
    "Ontario",
    "Prince Edward Island",
    "Quebec",
    "Saskatchewan",
    "Northwest Territories",
    "Nunavut",
    "Yukon",
  ];

  /**Section 3 */
  // State for Type select input
  const [employmentType, setEmploymentType] = useState("Full Time");
  const employmentTypes = ["Full Time", "Part Time", "Contract"];
  const [occupation, setOccupation] = useState("");
  const [grossIncome, setGrossIncome] = useState("");
  const [presentEmployer, setPresentEmployer] = useState("");
  const [employerPhone, setEmployerPhone] = useState("");
  const [employerStreetAddress, setEmployerStreetAddress] = useState("");
  const [employerCity, setEmployerCity] = useState("");
  const [employerProvince, setEmployerProvince] = useState("Ontario");
  const [employerPostalCode, setEmployerPostalCode] = useState("");
  const [employmentDurationYears, setEmploymentDurationYears] = useState("");
  const [employmentDurationMonths, setEmploymentDurationMonths] = useState("");

  /**Section 4 */
  const [bankrupt, setBankrupt] = useState("No");
  const [repo, setRepo] = useState("No");
  const [cosign, setCosign] = useState("No");

  const booleanOptions = ["Yes", "No"];
  /**Section 5 */
  const [downpayment, setDownpayment] = useState(0);
  const [agreement, setAgreement] = useState(false);

  const [submitted, setSubmitted] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();

    addDoc(collection(db, "submissions"), {
      to: window.location.hostname,
      form: "Financing Request",
      email,
      phone,
      name,
      birthday,
      maritalStatus,
      sin,
      addressType,
      monthlyPayment,
      addressLine1,
      addressCity,
      addressProvince,
      addressPostCode,
      addressDuration,
      prevAddressLine1,
      prevAddressCity,
      prevAddressProvince,
      prevAddressPostCode,
      employmentType,
      occupation,
      grossIncome,
      presentEmployer,
      employerPhone,
      employerStreetAddress,
      employerCity,
      employerProvince,
      employerPostalCode,
      employmentDurationYears,
      employmentDurationMonths,
      bankrupt,
      repo,
      cosign,
      downpayment,
      agreement,
      submissionDate: new Date().toLocaleDateString(),
    }).then((doc) => {
      console.log("CREATED SUBMISSION DOC", doc.id);
      setSubmitted(true);

      setTimeout(() => {
        setSubmitted(false);
      }, 6000);
    });
  };

  useEffect(() => {
    setHash("finance", true);
  }, []);

  const nextStep = () => {
    setCurrStep((currValue) => ++currValue);
  };

  const prevStep = () => {
    setCurrStep((currValue) => Math.max(0, --currValue));
  };

  const FormControls = ({ back, submit }) => (
    <div className={"formControls"}>
      {back && (
        <button onClick={prevStep} className={"outline"}>
          Previous
        </button>
      )}
      {!submit && (
        <button onClick={nextStep} className={"primary"}>
          Next
        </button>
      )}
      {submit && (
        <button onClick={onSubmit} className={"primary"}>
          Submit
        </button>
      )}
    </div>
  );

  return (
    <div key={"overlay"} className={"modalOverlay"} onClick={props.exit}>
      <div
        className={"modalContainer"}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div>
          <div className={"modalHeader"}>
            <FontAwesomeIcon
              className={"backIcon"}
              icon={faArrowLeft}
              onClick={() => {
                if (props.exit) props.exit();
              }}
            />

            <h2>Financing Request</h2>
          </div>
          <div className={"modalContents"}>
            <p>
              Get preapproved for financing now and save time at the dealership
            </p>

            <ProgressBar progress={progressPercent} />
            <div
              className={"formContainer"}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {currStep === 0 && (
                <div className={"formSection"}>
                  <h2>Personal Details</h2>
                  <label>
                    Name:
                    <input
                      placeholder={"John Doe"}
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    ></input>
                  </label>
                  <label>
                    Birthday
                    <input
                      type='date'
                      autoComplete='off'
                      selected={birthday}
                      onChange={(date) => setBirthday(date)}
                    />
                  </label>

                  <label>
                    Phone:
                    <input
                      placeholder={"888-888-8888"}
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                    ></input>
                  </label>
                  <label>
                    Email:
                    <input
                      placeholder={"John.Doe@gmail.com"}
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    ></input>
                  </label>

                  <label>
                    Marital Status:
                    <select
                      placeholder={"Marital Status"}
                      value={maritalStatus}
                      onChange={(e) => {
                        setMaritalStatus(e.target.value);
                      }}
                    >
                      {statuses.map((s) => (
                        <option value={s}>{s}</option>
                      ))}
                    </select>
                  </label>

                  <label>
                    SIN:
                    <input
                      placeholder={" 123-456-789"}
                      value={sin}
                      onChange={(e) => {
                        setSin(e.target.value);
                      }}
                    ></input>
                  </label>

                  <FormControls back={false} submit={false} />
                </div>
              )}

              {currStep === 1 && (
                <div className={"formSection"}>
                  <h2>Housing Details</h2>
                  <label>
                    Housing Type:
                    <select
                      placeholder={"Address Type"}
                      value={addressType}
                      onChange={(e) => {
                        setAddressType(e.target.value);
                      }}
                    >
                      {addressTypes.map((t) => (
                        <option value={t}>{t}</option>
                      ))}
                    </select>
                  </label>

                  <label>
                    Monthly Housing Cost:
                    <input
                      placeholder={"2,000$"}
                      value={monthlyPayment}
                      onChange={(e) => {
                        setMonthlyPayment(e.target.value);
                      }}
                    ></input>
                  </label>

                  <label>
                    Address:
                    <input
                      placeholder={"Street #"}
                      value={addressLine1}
                      onChange={(e) => {
                        setAddressLine1(e.target.value);
                      }}
                    ></input>
                  </label>
                  <label>
                    City:
                    <input
                      placeholder={"City"}
                      value={addressCity}
                      onChange={(e) => {
                        setAddressCity(e.target.value);
                      }}
                    ></input>
                  </label>
                  <label>
                    Province:
                    <select
                      placeholder={"Province"}
                      value={addressProvince}
                      onChange={(e) => {
                        setAddressProvince(e.target.value);
                      }}
                    >
                      {canadianProvinces.map((p) => (
                        <option value={p}>{p}</option>
                      ))}
                    </select>
                  </label>
                  <label>
                    Postal Code:
                    <input
                      placeholder={"X1X 1X1"}
                      value={addressPostCode}
                      onChange={(e) => {
                        setAddressPostCode(e.target.value);
                      }}
                    ></input>
                  </label>
                  <label>
                    # Years at Current Address:
                    <input
                      type={"number"}
                      placeholder={"1"}
                      value={addressDuration}
                      onChange={(e) => {
                        setAddressDuration(e.target.value);
                      }}
                    ></input>
                  </label>
                  {addressDuration && parseInt(addressDuration) <= 3 && (
                    <>
                      <hr />
                      <h2>Previous Address:</h2>
                      <label>
                        Address:
                        <input
                          placeholder={"Street #"}
                          value={prevAddressLine1}
                          onChange={(e) => {
                            setPrevAddressLine1(e.target.value);
                          }}
                        ></input>
                      </label>
                      <label>
                        City:
                        <input
                          placeholder={"City"}
                          value={prevAddressCity}
                          onChange={(e) => {
                            setPrevAddressCity(e.target.value);
                          }}
                        ></input>
                      </label>
                      <label>
                        Province:
                        <select
                          placeholder={"Province"}
                          value={prevAddressProvince}
                          onChange={(e) => {
                            setPrevAddressProvince(e.target.value);
                          }}
                        >
                          {canadianProvinces.map((p) => (
                            <option value={p}>{p}</option>
                          ))}
                        </select>
                      </label>
                      <label>
                        Postal Code:
                        <input
                          placeholder={"X1X 1X1"}
                          value={prevAddressPostCode}
                          onChange={(e) => {
                            setPrevAddressPostCode(e.target.value);
                          }}
                        ></input>
                      </label>
                    </>
                  )}
                  <FormControls back={true} submit={false} />
                </div>
              )}
              {currStep === 2 && (
                <div className={"formSection"}>
                  <h2>Employment Details</h2>
                  <label>
                    Employment Type:
                    <select
                      value={employmentType}
                      onChange={(e) => setEmploymentType(e.target.value)}
                    >
                      {employmentTypes.map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                  </label>

                  <label>
                    Occupation:
                    <input
                      placeholder={"Occupation"}
                      value={occupation}
                      onChange={(e) => setOccupation(e.target.value)}
                    />
                  </label>

                  <label>
                    Gross Income:
                    <input
                      placeholder={"Gross Income"}
                      value={grossIncome}
                      onChange={(e) => setGrossIncome(e.target.value)}
                    />
                  </label>
                  <label>
                    Present Employer:
                    <input
                      placeholder={""}
                      value={presentEmployer}
                      onChange={(e) => setPresentEmployer(e.target.value)}
                    />
                  </label>
                  <label>
                    Employer Phone:
                    <input
                      placeholder={"(222) 222-2222"}
                      value={employerPhone}
                      onChange={(e) => setEmployerPhone(e.target.value)}
                    />
                  </label>
                  <label>
                    Years at current employer:
                    <input
                      type={"number"}
                      placeholder={"Years"}
                      value={employmentDurationYears}
                      onChange={(e) =>
                        setEmploymentDurationYears(e.target.value)
                      }
                    />
                  </label>
                  <label>
                    Months at current employer:
                    <input
                      type={"number"}
                      placeholder={"Months"}
                      value={employmentDurationMonths}
                      onChange={(e) =>
                        setEmploymentDurationMonths(e.target.value)
                      }
                    />
                  </label>
                  <h2>Employer's Address:</h2>
                  <label>
                    Address:
                    <input
                      placeholder={"Street #"}
                      value={employerStreetAddress}
                      onChange={(e) => {
                        setEmployerStreetAddress(e.target.value);
                      }}
                    ></input>
                  </label>
                  <label>
                    City:
                    <input
                      placeholder={"City"}
                      value={employerCity}
                      onChange={(e) => {
                        setEmployerCity(e.target.value);
                      }}
                    ></input>
                  </label>
                  <label>
                    City:
                    <select
                      placeholder={"Province"}
                      value={employerProvince}
                      onChange={(e) => {
                        setEmployerProvince(e.target.value);
                      }}
                    >
                      {canadianProvinces.map((p) => (
                        <option value={p}>{p}</option>
                      ))}
                    </select>
                  </label>
                  <label>
                    Postal Code:
                    <input
                      placeholder={"X1X 1X1"}
                      value={employerPostalCode}
                      onChange={(e) => {
                        setEmployerPostalCode(e.target.value);
                      }}
                    ></input>
                  </label>

                  <FormControls back={true} submit={false} />
                </div>
              )}

              {currStep === 3 && (
                <div className={"formSection"}>
                  <h2>Credit History</h2>
                  <label>
                    Have you declared bankruptcy in the last 7 years?
                    <select
                      type={"checkbox"}
                      value={bankrupt}
                      onChange={(e) => {
                        setBankrupt(e.target.value);
                      }}
                    >
                      {booleanOptions.map((o) => (
                        <option value={o}>{o}</option>
                      ))}
                    </select>
                  </label>
                  <label>
                    Have you ever had a vehicle repossessed?
                    <select
                      type={"checkbox"}
                      value={repo}
                      onChange={(e) => {
                        setRepo(e.target.value);
                      }}
                    >
                      {booleanOptions.map((o) => (
                        <option value={o}>{o}</option>
                      ))}
                    </select>
                  </label>
                  <label>
                    Will you have a cosigner for this loan?
                    <select
                      type={"checkbox"}
                      value={cosign}
                      onChange={(e) => {
                        setCosign(e.target.value);
                      }}
                    >
                      {booleanOptions.map((o) => (
                        <option value={o}>{o}</option>
                      ))}
                    </select>
                  </label>
                  <label>
                    Down payment amount:
                    <input
                      type={"number"}
                      placeholder={"0"}
                      value={downpayment}
                      onChange={(e) => setDownpayment(e.target.value)}
                    />
                  </label>

                  <FormControls back={true} submit={false} />
                </div>
              )}

              {currStep === 4 && (
                <div className={"formSection"}>
                  <h2>Finalize Application</h2>
                  <div className={"checkboxWrapper"}>
                    <label>
                      <input
                        onChange={() => {
                          setAgreement((curr) => !curr);
                        }}
                        className={agreement ? "checked" : ""}
                        type={"checkbox"}
                      />
                      I certify that each of the statements made and answers
                      given in this credit application are true and correct and
                      is made for the purpose of inducing the financing of the
                      purchase of a motor vehicle. I authorize the obtaining of
                      a consumer report to be used in evaluating this
                      application and the obtaining and exchanging of credit
                      information from and with other creditors and consumer
                      reporting agencies.
                    </label>
                  </div>
                  <FormControls back={true} submit={true} />
                </div>
              )}
            </div>
            {/* <button
              className={"modalCTA"}
              onClick={(e) => {
                e.stopPropagation();
                onSubmit();
              }}
            >
              Submit Request
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
