import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

const config = {
  apiKey: "AIzaSyDVUL5h8Kohf9oOM96UmoIECRoakhJV1iU",
  authDomain: "clipclop-273921.firebaseapp.com",
  projectId: "clipclop-273921",
  storageBucket: "clipclop-273921.appspot.com",
  messagingSenderId: "996386297409",
  appId: "1:996386297409:web:668b712ba222ef020cd8c0",
};

const app = initializeApp(config);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);

// // Initialize Firebase
// if (process.env.NODE_ENV === "development") {
//   connectFirestoreEmulator(db, "localhost", 8080);
// }


