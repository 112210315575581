export function intToDollars(amount) {
  return (
    "$" +
    Math.floor(amount / 100000) +
    "," +
    Math.floor((amount % 100000) / 100)
      .toString()
      .padStart(3, "0") +
    "." +
    (amount % 100).toString().padStart(2, "0")
  );
}

export function intToMileage(amount) {
  return (
    (Math.floor(amount / 1000) > 0 ? Math.floor(amount / 1000) + "," : "") +
    +Math.floor(amount % 1000)
      .toString()
      .padStart(3, "0") +
    " km"
  );
}
