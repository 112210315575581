import joinStyles from "../utils/joinStyles";

import styles from "../styles/navMenu.module.scss";

import { useEffect, useState } from "react";
import PrimeAutoLogo from "../assets/primeAuto.png";
import Service from "./Service";
import Finance from "./Finance";
import { parseHash } from "../utils/url";
export default function NavMenu() {
  const [forceMobileOpen, setForceMobileOpen] = useState(undefined);

  const [financeOpen, setFinanceOpen] = useState(false);
  const [serviceOpen, setServiceOpen] = useState(false);

  useEffect(() => {
    const hash = parseHash(window.location.hash);
    if (hash.finance) {
      setFinanceOpen(true);
    } else if (hash.service) {
      setServiceOpen(true);
    }
  }, []);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = parseHash(window.location.hash);

      if (hash.finance) {
        setFinanceOpen(true);
      } else if (hash.service) {
        setServiceOpen(true);
      } else {
        setFinanceOpen(false);
        setServiceOpen(false);

      }
    };
    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  return (
    <>
      <div className={joinStyles(styles.navMenu, styles.light)}>
        <div className={styles.navLogo}>
          <img src={PrimeAutoLogo} />
        </div>
        <div className={styles.desktopNavItems}>
          <a
            className={styles.navItem}
            onClick={() => {
              setFinanceOpen(true);
            }}
          >
            Financing
          </a>
          <a className={styles.navItem} href='#inventory'>
            Inventory
          </a>
          <a
            className={styles.navItem}
            onClick={() => {
              setServiceOpen(true);
            }}
          >
            Service
          </a>
        </div>
        <button
          onClick={() => {
            setForceMobileOpen(!forceMobileOpen);
          }}
          className={joinStyles(
            styles.menuBtn,
            forceMobileOpen ? styles.open : ""
          )}
          aria-label='Toggle Menu'
        >
          <div className={styles.menuBtnBurger}></div>
        </button>

        {forceMobileOpen !== undefined ? (
          <div
            className={joinStyles(
              styles.mobileMenu,
              forceMobileOpen ? styles.open : ""
            )}
            onClick={() => {
              setForceMobileOpen(false);
            }}
          >
            <button
              className={styles.mobileSubNavItem}
              onClick={() => {
                setFinanceOpen(true);
              }}
            >
              Financing
            </button>
            <a className={styles.mobileSubNavItem} href='#inventory'>
              Inventory
            </a>
            <button
              className={styles.mobileSubNavItem}
              onClick={() => {
                setServiceOpen(true);
              }}
            >
              Service
            </button>
          </div>
        ) : null}
      </div>

      <div className={styles.navSpacer}></div>

      {/** Modals */}
      {serviceOpen ? (
        <Service
          exit={() => {
            setServiceOpen(false);
            window.history.back();
          }}
        />
      ) : null}

      {financeOpen ? (
        <Finance
          exit={() => {
            setFinanceOpen(false);
            window.history.back();
          }}
        />
      ) : null}
    </>
  );
}
