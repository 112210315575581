import { useEffect, useState } from "react";
import { db } from "./utils/firebase";
import { collection, getDocs } from "firebase/firestore";
import NavMenu from "./components/NavMenu";
import "./styles/global.scss";
import splashImage from "./assets/car3.png";
import Spinner from "./components/Spinner";
import { intToDollars, intToMileage } from "./utils/numbers";
import CurrentListing from "./components/CurrentListing";
import Footer from "./components/Footer";

import comingSoonImage from "./assets/coming_soon.jpg";
import { parseHash } from "./utils/url";
function App() {
  const [listings, setListings] = useState([]);
  const [currentListing, setCurrentListing] = useState(null);
  useEffect(() => {
    getDocs(collection(db, "merchants/petersdavis@gmail.com/listings"))
      .then((querySnapshot) => {
        const listings = querySnapshot.docs.map((doc) => doc.data());
        setListings(listings);

        const hash = parseHash(window.location.hash);
        if (hash.listingId) {
          const activeListing = listings.find((l) => {
            return l.id == hash.listingId;
          });
          setCurrentListing(activeListing);
        } 
      })
      .catch((err) => {
        console.log("firebase error", err);
      });
  }, []);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = parseHash(window.location.hash);

      if (!hash.listingId && currentListing !== null) {
        setCurrentListing(null);
      }
    };
    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  });

  console.log(listings);

  return (
    <div
      className='App'
      style={currentListing !== null ? { overflowY: "scroll" } : null}
    >
      <NavMenu />
      <div
        className={"splashHeader"}
        style={{ backgroundImage: `url(${splashImage})` }}
      >
        <div className={"splashOverlay"}></div>
      </div>

      <div className={"section"}>
        <h1 id='inventory'>Current Inventory:</h1>

        <div className={"listContainer"}>
          {!listings.length ? (
            <Spinner />
          ) : (
            listings.map((listing, idx) => (
              <div
                key={idx}
                className={"listItem"}
                onClick={() => {
                  setCurrentListing(listing);
                }}
              >
                <h2 className={"listingTitle"}>{listing.title}</h2>
                {listing.mileage && <h4>{intToMileage(listing.mileage)}</h4>}

                <img
                  src={
                    listing.images.length ? listing.images[0] : comingSoonImage
                  }
                  className={"listingThumb"}
                />
                <div className={"listingCTAWrap"}>
                  <button className={"listingCTA"}>
                    {intToDollars(listing.price)}{" "}
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <Footer />

      {/** Modals */}
      {currentListing === null ? null : (
        <CurrentListing
          listing={currentListing}
          exit={() => {
            window.history.back();
          }}
        />
      )}
    </div>
  );
}

export default App;
