import React, { useState } from "react";
import styles from "../styles/TextWithEllipsis.module.scss";

const TextWithEllipsis = ({ text, maxLines = 2 }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <div
        className={expanded ? styles.unclampedText : styles.clampedText}
        onClick={toggleExpansion}
      >
        {text}
      </div>
      {expanded ? (
        <div>
          <button className={styles.showLessButton} onClick={toggleExpansion}>
            Show Less
          </button>
        </div>
      ) : (
        <button className={styles.showMoreButton} onClick={toggleExpansion}>
          Show More
        </button>
      )}
    </div>
  );
};

export default TextWithEllipsis;
