import styles from "../styles/spinner.module.scss";

export default function Spinner(props) {
  return (
    <div className={styles.container}>
      <div className={styles.cube}>
        <div className={styles.sides}>
          <div className={styles.top}></div>
          <div className={styles.right}></div>
          <div className={styles.bottom}></div>
          <div className={styles.left}></div>
          <div className={styles.front}></div>
          <div className={styles.back}></div>
        </div>
      </div>
    </div>
  );
}
